  .main-container {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    overflow: hidden;
  }
  
  .constellation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .star {
    background-color: white;
    border-radius: 50%;
    position: absolute;
    animation-name: star;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  
  .star.style1 { animation-duration: 2s; animation-name: star; }
  .star.style2 { animation-duration: 2.5s; animation-name: star; }
  .star.style3 { animation-duration: 3s; animation-name: star; }
  .star.style4 { animation-duration: 3.5s; animation-name: starHighlighted; }
  
  .star.size1 { width: 1px; height: 1px; }
  .star.size2 { width: 2px; height: 2px; }
  .star.size3 { width: 3px; height: 3px; }
  
  .star.opacity1 { opacity:  1; }
  .star.opacity2 { opacity: .5; }
  .star.opacity3 { opacity: .1; }
  
  .meteor {
    z-index: 10;
    border-top-left-radius: 20%;
    border-top-right-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, .75)
    );
    width: 3px;
    height: 3px;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-duration: 6s;
    /* animation-delay: 3s; */
  }
  @keyframes animShootingStar {
    from {
      transform: translateY(0px) translateX(0px) rotate(115deg);
      opacity: 1;
      height: 1%;
    }
    to {
      transform: translateY(1260px)
        translateX(2560px) rotate(115deg);
      opacity: 1;
      height: 30%;
    }
  }
  /* .meteor:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    width: 0;
    height: 0;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-left: 85px solid white;
    position: absolute;
    left: 2px;
    top: 0;
  }
   */
  .meteor.style1 { animation-name: meteorStyle1; }
  .meteor.style2 { animation-name: meteorStyle2; }
  .meteor.style3 { animation-name: meteorStyle3; }
  .meteor.style4 { animation-name: meteorStyle4; }
  
  /* .meteor.style1 { animation-name: animShootingStar; }
  .meteor.style2 { animation-name: animShootingStar; }
  .meteor.style3 { animation-name: animShootingStar; }
  .meteor.style4 { animation-name: animShootingStar; } */

  @keyframes darken {
    0%   { top: 0; }
    100% { top: 100%; }
  }
  
  @keyframes star {
    0% {
      box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
      opacity: 0.1;
    }
    50% {
      box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.4);
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.05);
      opacity: 0;
    }
  }
  
  @keyframes starHighlighted {
    0% {
      background-color: #FFFFFF;
      /* box-shadow: 0 0 10px 0px rgba(255, 255, 255, 1); */
      opacity: 0.1;
    }
    20% {
      background-color: #FFFFFF;
      /* box-shadow: 0 0 10px 0px rgb(255, 196, 196, 0.5); */
      opacity: 1;
    }
    80% {
      background-color: #FFFFFF;
      /* box-shadow: 0 0 10px 0px rgb(196, 207, 255, 1); */
      opacity: 1;
    }
    100% {
      background-color: #FFFFFF;
      /* box-shadow: 0 0 10px 0px rgba(255, 255, 255, 0.2); */
      opacity: 0;
    }
  }
  
  @keyframes meteorStyle1 {
    from {
      transform: translateY(0px) translateX(0px) rotate(125deg);
      opacity: 1;
      height: 1%;
    }
    to {
      transform: translateY(2560px)
        translateX(2560px) rotate(125deg);
      opacity: 1;
      height: 20%;
    }
  }
  
  @keyframes meteorStyle2 {
    from {
      transform: translateY(200px) translateX(300px) rotate(105deg);
      opacity: 1;
      height: 1%;
    }
    to {
      transform: translateY(900px)
        translateX(2560px) rotate(105deg);
      opacity: 1;
      height: 20%;
    }
  }
  
  @keyframes meteorStyle3 {
    from {
      transform: translateY(100px) translateX(200px) rotate(135deg);
      opacity: 1;
      height: 1%;
    }
    to {
      transform: translateY(2560px)
        translateX(2560px) rotate(135deg);
      opacity: 1;
      height: 15%;
    }
  }
  
  @keyframes meteorStyle4 {
    from {
      transform: translateY(0px) translateX(0px) rotate(115deg);
      opacity: 1;
      height: 1%;
    }
    to {
      transform: translateY(1260px)
        translateX(2560px) rotate(115deg);
      opacity: 1;
      height: 15%;
    }
  }
  
  @keyframes rotate {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }